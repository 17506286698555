import React from "react"
import { graphql } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"




// import { data } from "jquery"

const FaqPostTemplate = ({ data: { //previous, next, 
  post } }) => {

    return (
      <div className="container my-4">
        {/* <h2><Link to="/faq-list/">Back</Link></h2> */}
        <article
          className="blog-post clearfix"
          itemScope
          itemType="http://schema.org/Article"
        >
          {!!post.content && (
            <div>
            <h4>{post.title}</h4>
            <section itemProp="articleBody">{parse(post.content)}</section>
            </div>
          )}
        </article>
        {/* <div className="blog-post-nav d-flex flex-wrap justify-content-between">
          {previous && (
            ""
            // <Link to={previous.uri} rel="prev" className="btn btn-blue btn-sm my-2">
            //   ← {parse(previous.title)}
            // </Link>
          )}
          {next && (
            ""
            // <Link to={next.uri} rel="next" className="btn btn-blue btn-sm my-2">
            // {parse(next.title)} →
            // </Link>
          )}
        </div> */}
      </div>
  )
}

export default FaqPostTemplate

export const pageQuery = graphql`
query FaqPostById($id: String, $previousPostId: String, $nextPostId: String) {
    post: wpPost(
      id: {eq: $id}

    ) {
      id
      excerpt
      content
      title
      categories{
        nodes{
        name
     }
    }
    }
    previous: wpPost(
      id: {eq: $previousPostId}

    ) {
      uri
      title
    }
    next: wpPost(
      id: {eq: $nextPostId}
    ) {
      uri
      title
    }
  }  
`
